import queryString from 'query-string';
import { isNil, pick } from 'lodash-es';

export const businessAddressListParams = (options) => {
  const { statusIds, searchText, businessName, creationDateFrom, creationDateTo, modifiedDateFrom, modifiedDateTo } =
    options;

  return queryString.stringify(
    {
      ...pick(options, ['pageNumber', 'pageSize']),
      ...(searchText && searchText?.length && { searchText }),
      ...(businessName && businessName?.length && { businessName }),
      ...(!isNil(statusIds) && { statusIds }),
      ...(!isNil(creationDateFrom) && { creationDateFrom }),
      ...(!isNil(creationDateTo) && { creationDateTo }),
      ...(!isNil(modifiedDateFrom) && { modifiedDateFrom }),
      ...(!isNil(modifiedDateTo) && { modifiedDateTo }),
    },
    { arrayFormat: 'index' },
  );
};
