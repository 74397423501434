import { theme } from 'styles';

export const drawerWrapper = (isSidebarOpen) => [
  {
    backgroundColor: theme.background100,
    flex: `0 0 ${isSidebarOpen ? 16 : 3}rem`,
    transition: 'flex-basis 0.5s ease-in',
    height: '100vh',
    overflow: 'auto',
  },
  {
    '& .ant-menu-title-content': {
      opacity: '1',
      transition: 'opacity 0.5s ease-in-out',
    },
    '& a': {
      opacity: '1',
      transition: 'opacity 0.5s ease-in-out',
    },
  },
  !isSidebarOpen && {
    '& .ant-menu-title-content': {
      opacity: '0',
      whiteSpace: 'nowrap',
    },
    '& a': {
      opacity: '0',
    },
  },
];

export const logoImage = {
  height: '2.625rem',
  margin: '1em',
  [theme.Mobile]: {
    height: '2rem',
  },
};

export const sidebarContainer = {
  width: '100%',
  '& .ant-menu': { background: theme.background100 },
  '& .ant-menu-title-content': {
    transition: 'opacity 0.5s ease-in-out !important',
    whiteSpace: 'nowrap',
    '& a': {
      color: 'white !important',
      fontSize: '1rem',
      fontWeight: 400,
    },
  },
  '& .ant-menu-item-icon': { color: 'white !important', fontSize: '1.125rem !important', display: 'flex' },
  '& .ant-btn': { color: 'white !important', padding: 0 },
  '& .ant-menu-inline > .ant-menu-item': {
    whiteSpace: 'normal',
    height: 'auto',
    lineHeight: '1.2',
    padding: '1em !important',
    margin: 0,
  },
  '& .ant-menu-item-selected': {
    boxShadow: 'inset 4px 0px 0px #FFCF00 !important',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #19285E !important',
  },
  '& .icon svg': {
    color: '#fff',
    strokeWidth: 1,
    marginRight: '0.625em',
  },
};

export const translationsSideBarStyles = {
  color: theme.white,
  padding: 0,
  whiteSpace: 'break',
};

export const logoAndHamburgerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1em 1em .5em',
};

export const hamburgerMenu = {
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  '& i': {
    fontSize: '1.125rem !important',
    color: 'white',
  },
};
