import { theme } from 'styles';

export const localizationContainer = (isMobileView) => ({
  fontSize: '0.75rem',
  display: isMobileView ? 'flex' : 'block',
  justifyContent: isMobileView ? 'space-between' : 'initial',
});

export const localizationContent = {
  color: theme.white,
  cursor: 'pointer',
  paddingLeft: '0.25em',
};
