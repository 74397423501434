import { getReq, postReq, patchReq, putReq } from 'services/axios/makeRequest';
import { businessAddressListParams } from 'services/models/businessAddresses';

const baseUrl = `${apiUrls.dealshakerAdmin}/api/BusinessAddresses`;

export const getAllBusinessAddresses = (options) => getReq(`${baseUrl}/List?${businessAddressListParams(options)}`);

export const getBusinessAddressById = (addressId) => getReq(`${baseUrl}/Get/${addressId}`);

export const updateBusinessAddressStatus = (data) => patchReq(`${baseUrl}/UpdateStatus`, data);

export const addCommentToBusinessAddress = (data) => postReq(`${baseUrl}/Comment`, data);

export const updateBusinessAddress = (addressId, data) => putReq(`${baseUrl}/Update/${addressId}`, data);
