/* eslint-disable default-case */
import PropTypes from 'prop-types';
import { sub, mul } from 'exact-math';
import { isNumber } from 'lodash-es';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, printPrice, getDealFinalPrices } from '@oneecosystem/dealshaker-core';
import { priceContainer, priceStyles, beforeDiscountStyles, savingStyles } from './styles';

export const DealPrice = ({ deal, showSavings, showFinalPrice, className, dataId }) => {
  useCurrencies((currencies) => currencies.selectedCurrency);
  const { getText } = useTranslations();

  const hasFiatPrice = isNumber(deal?.priceFiat);
  const hasDiscount = isNumber(deal?.discountedPriceFiat) || isNumber(deal?.discountedPriceCrypto);
  const applyQuantity = (price) =>
    isNumber(deal?.quantity) && isNumber(price) && deal.quantity > 1 ? mul(deal.quantity, price) : price;
  const priceFiat = hasFiatPrice && applyQuantity(deal.priceFiat);
  const priceCrypto = applyQuantity(deal?.priceCrypto);
  const discountedPriceFiat = hasDiscount && applyQuantity(deal?.discountedPriceFiat);
  const discountedPriceCrypto = applyQuantity(deal?.discountedPriceCrypto);
  const finalPrice = getDealFinalPrices(deal);
  const savingFiat = hasDiscount && sub(deal.price, deal.discountedPrice);
  const fiatOriginCurrency = deal?.currencyCode;

  const priceComponent = () => {
    switch (true) {
      case showFinalPrice:
        return (
          <p className={priceStyles} data-id="deal-price-amount" data-fiat={priceFiat} data-crypto={priceCrypto}>
            {printPrice({
              fiat: finalPrice.priceFiat,
              fiatCrypto: finalPrice.priceCrypto,
              fiatOriginCurrency,
              percentage: deal?.percentRatio,
            })}
            {!!deal?.quantity && <span className="quantity"> x {deal.quantity}</span>}
          </p>
        );
      case hasDiscount:
        return (
          <>
            <p
              className={beforeDiscountStyles}
              data-id="deal-price-amount"
              data-fiat={priceFiat}
              data-crypto={priceCrypto}>
              {printPrice({
                fiat: priceFiat,
                fiatCrypto: priceCrypto,
                fiatOriginCurrency,
                percentage: deal?.percentRatio,
              })}
            </p>
            <p
              className={`${priceStyles} ${className ?? ''}`}
              data-id="deal-price-discounted-amount"
              data-discounted-fiat={discountedPriceFiat}
              data-discounted-crypto={discountedPriceCrypto}>
              {printPrice({
                fiat: discountedPriceFiat,
                fiatCrypto: discountedPriceCrypto,
                fiatOriginCurrency,
                percentage: deal?.percentRatio,
              })}
            </p>
          </>
        );
      default:
        return (
          <p className={priceStyles} data-id="deal-price-amount" data-fiat={priceFiat} data-crypto={priceCrypto}>
            {printPrice({
              fiat: priceFiat,
              fiatCrypto: priceCrypto,
              fiatOriginCurrency,
              percentage: deal?.percentRatio,
            })}
          </p>
        );
    }
  };

  return (
    <div
      className={priceContainer(className)}
      data-id={dataId ?? 'deal-price-panel'}
      data-deal-id={deal?.id}
      data-price={deal?.price}
      data-has-discount={!!deal?.discountedPrice}
      data-discount={deal?.discount}
      data-quantity={deal?.quantity}
      data-percent={deal?.percentRatio}>
      {priceComponent()}

      {showSavings && hasDiscount && (
        <p className={savingStyles} data-id="save-approximately-price" data-savings={savingFiat}>
          {getText('saveApproximatelyPrice', { price: printPrice({ fiat: savingFiat, fiatOriginCurrency }) })}
        </p>
      )}
    </div>
  );
};

DealPrice.propTypes = {
  deal: PropTypes.object,
  showSavings: PropTypes.bool,
  showFinalPrice: PropTypes.bool,
  className: PropTypes.string,
  dataId: PropTypes.string,
};
