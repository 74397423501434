import PropTypes from 'prop-types';
import { isNil } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { Tag } from 'components/UIExternal';
import { Skeleton } from 'components/UIExternal';
import { deliveryMethodInverse, deliveryStatusInverse, deliveryStatusColor } from 'enums';
import { useOrders } from 'stores';
import { orderDetailsDeliveryAddressContainer } from './styles';

export const DeliveryAddressInfo = () => {
  const { getText } = useTranslations();
  const delivery = useOrders((ordersState) => ordersState?.selectedOrder?.delivery);

  const country = delivery?.buyerDeliveryAddress?.country ?? delivery?.businessPickupAddress?.country;
  const city = delivery?.buyerDeliveryAddress?.city ?? delivery?.businessPickupAddress?.city;
  const street = delivery?.buyerDeliveryAddress?.street ?? delivery?.businessPickupAddress?.street;
  const address = [street?.trim(), city?.trim(), country?.trim()].filter(Boolean).join(', ') || 'N/A';

  return (
    <section css={orderDetailsDeliveryAddressContainer}>
      <section className="order-details-delivery-section">
        <div className="order-details-delivery-info-row">
          <p className="gray order-details-delivery-label">{getText('type')}</p>
          <span>
            {isNil(delivery) ? (
              <Skeleton height={20} width={150} />
            ) : (
              getText(deliveryMethodInverse[delivery?.deliveryMethodId])
            )}
          </span>
        </div>
        <div className="order-details-delivery-info-row">
          <p className="gray order-details-delivery-label">{getText('status')}</p>
          {isNil(delivery) ? (
            <Skeleton height="1.5rem" width="7rem" />
          ) : (
            <Tag type={deliveryStatusColor[delivery?.statusId]} fontSize="0.8rem">
              {deliveryStatusInverse[delivery?.statusId]}
            </Tag>
          )}
        </div>
      </section>
      <section className="order-details-delivery-section">
        <div className="order-details-delivery-info-row">
          <p className="gray align-start order-details-delivery-label">{getText('address')}</p>
          {isNil(delivery) ? <Skeleton width={100} height={20} /> : <span>{address}</span>}
        </div>
      </section>
    </section>
  );
};

DeliveryAddressInfo.propTypes = {
  edited: PropTypes.bool,
  orderDetail: PropTypes.object,
};
