import { theme } from 'styles';

export const paginationContainer = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexWrap: 'nowrap',
  fontSize: '0.85rem',

  '& .pagination-dots': {
    margin: '0 0.25em',
  },
};

export const shownResultsIndicator = {
  color: theme.gray200,
  textAlign: 'center',
  marginLeft: '0.5em',
  fontSize: '0.65rem',
};

export const paginationButton = (disabled, isActive) => [
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    width: '1.75rem',
    height: '1.75rem',
    margin: '0 0.125em',
    borderRadius: '50%',
    '&:hover': !disabled &&
      !isActive && {
        backgroundColor: theme.gray400,
      },
  },
  isActive && {
    backgroundColor: theme.gray600,
  },
  disabled && {
    cursor: 'not-allowed',
    color: theme.gray300,
  },
];

export const pageSizeDropdown = {
  width: '5rem',
};
